import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

import { debounce } from 'lodash'
import { Link } from 'react-router-dom'
import series from '../Series/Series'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as IconTrash } from '../../assets/icon-trash.svg'
import { Checkbox } from 'primereact/checkbox'
import useLocalStorage from '../../hooks/useLocalStorage'

const Archived = (props: any) => {
  const [seriesList, setSeriesList] = useState<any>({})
  const [filters, setFilters] = useLocalStorage('global-filters', {
    completed: true,
    notCompleted: true,
    lastCover: true,
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const loadSeries = async () => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/archived`)

    setSeriesList(response.data)
  }

  useEffect(() => {
    loadSeries()
  }, [])

  useEffect(() => {
    if (seriesList.data?.length) {
      window.scrollTo(0, props.scrollPosition)
    }

    setTimeout(() => {
      setIsLoading(false)
    }, 400)
  }, [seriesList])

  const setScrollPosition = () => {
    props.setScrollPosition((prev: any) => {
      return {
        ...prev,
        watchlist: window.scrollY,
      }
    })
  }

  const buildMangakaLinks = (mangakaList: any) => {
    if (mangakaList.length === 0) {
      return ''
    }

    return mangakaList
      .map((mangaka: any) => (
        <Link
          to={`/search?q=${mangaka.name}`}
          onClick={setScrollPosition}
          key={mangaka.id}
        >
          {mangaka.name}
        </Link>
      ))
      .reduce((prev: any, curr: any) => {
        return [prev, ', ', curr]
      })
  }

  return (
    <main>
      {isLoading && (
        <div className="loading-container">
          <div>
            <Logo className="loading-logo" />
          </div>
        </div>
      )}
      <div className="container">
        {seriesList.amount === 0 && !isLoading && (
          <div className="no-content">
            <span>Nothing Found</span>
          </div>
        )}

        {seriesList.amount > 0 && (
          <header className={`content-header ${isLoading ? ' hide-content' : ''}`}>
            <h2>
              <IconTrash /> Archived ({seriesList.amount})
            </h2>
          </header>
        )}

        <div className={`covers ${isLoading ? ' hide-content' : ''}`}>
          {seriesList.data?.map((series: any) => (
            <div className="series-container" key={series.id}>
              <Link
                to={`/series/${series.id}/${series.slug}`}
                className="cover-container"
                onClick={setScrollPosition}
              >
                <img
                  width="240"
                  height="360"
                  src={
                    series[filters.lastCover ? 'last_volume' : 'first_volume']
                      .cover_url
                  }
                />
              </Link>
              <div className="series-info-container">
                <div className="series-name">
                  <Link
                    onClick={setScrollPosition}
                    to={`/series/${series.id}/${series.slug}`}
                  >
                    {series.name}
                  </Link>
                </div>
                <span className="series-info">
                  {buildMangakaLinks(
                    series[filters.lastCover ? 'last_volume' : 'first_volume']
                      .mangaka
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  )
}

export default Archived
