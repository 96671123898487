import Header from './components/Header/Header'
import Home from './views/Home/Home'
import { Route, Routes, useLocation } from 'react-router-dom'
import Series from './views/Series/Series'
import { useEffect, useState } from 'react'
import SeriesDetail from './views/Series/SeriesDetail'
import Search from './views/Search/Search'
import Mangaka from './views/Mangaka/Mangaka'
import Watchlist from './views/Watchlist/Watchlist'
import Archived from './views/Archived/Archived'

const App = (props: any) => {
  const [scrollPositions, setScrollPositions] = useState({
    home: 0,
    watchlist: 0,
    archived: 0,
  })

  return (
    <>
      <Header setScrollPosition={setScrollPositions} />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              setScrollPosition={setScrollPositions}
              scrollPosition={scrollPositions.home}
            />
          }
        />
        <Route path="/search" element={<Search />} />
        <Route
          path="/watchlist"
          element={
            <Watchlist
              setScrollPosition={setScrollPositions}
              scrollPosition={scrollPositions.watchlist}
            />
          }
        />
        <Route
          path="/archived"
          element={
            <Archived
              setScrollPosition={setScrollPositions}
              scrollPosition={scrollPositions.archived}
            />
          }
        />
        <Route path="/mangaka" element={<Mangaka />} />
        <Route path="/series" element={<Series />} />
        <Route path="/series/:id">
          <Route path=":slug" element={<SeriesDetail />} />
          <Route path="" element={<SeriesDetail />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
