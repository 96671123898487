import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { Checkbox } from 'primereact/checkbox'
import { AutoComplete } from 'primereact/autocomplete'
import { MultiSelect } from 'primereact/multiselect'

const EditSeries = (props: any) => {
  const [allGenres, setAllGenres] = useState([])
  const [filteredGenres, setFilteredGenres] = useState([])
  const [series, setSeries] = useState<any>({
    series_id: 0,
    first_volume_id: 0,
    name: '',
    description: '',
    completed: false,
    watchlist: false,
    genres: [],
  })

  const save = async (event: any) => {
    event.preventDefault()

    // TODO: Check nach validierung

    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/series/${series.series_id}`,
      {
        ...series,
      }
    )

    props.onSave()
  }

  const searchGenre = (event: any) => {
    setTimeout(() => {
      let _filteredCountries
      if (!event.query.trim().length) {
        _filteredCountries = [...allGenres]
      } else {
        _filteredCountries = allGenres.filter((country: any) => {
          return country.name.toLowerCase().startsWith(event.query.toLowerCase())
        })
      }

      setFilteredGenres(_filteredCountries)
    }, 250)
  }

  const loadAllGenres = async () => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/genres`)

    setAllGenres(response.data)
  }

  useEffect(() => {
    loadAllGenres()

    console.log(props.series.genres)

    setSeries({
      name: props.series.name,
      completed: props.series.completed,
      watchlist: props.series.watchlist,
      archived: props.series.archived,
      description: props.series.first_volume.description,
      series_id: props.series.id,
      first_volume_id: props.series.first_volume.id,
      genres: props.series.genres,
    })
  }, [props.series])

  const setSelectedGenre = (value: any) => {
    setSeries((prev: any) => {
      return {
        ...prev,
        genres: value,
      }
    })
  }

  const responseFormHandler = (event: any) => {
    let targetName = ''
    let value = ''

    if (event.checked) {
      targetName = event.target.name
      value = event.checked
    } else if (event.target) {
      targetName = event.target.name
      value = event.target.value
    } else {
      // InputNumber returns an originalEvent property.
      targetName = event.originalEvent.target.name
      value = event.value
    }

    setSeries((prev: any) => {
      return {
        ...prev,
        [targetName]: value,
      }
    })
  }

  return (
    <div className="sidebar-content">
      <form onSubmit={save}>
        <Button type="submit">SAVE</Button>

        <div>
          <label htmlFor="title">Completed</label>
          <Checkbox
            name="completed"
            onChange={responseFormHandler}
            checked={series.completed}
          ></Checkbox>
        </div>

        <div>
          <label htmlFor="title">Watchlist</label>
          <Checkbox
            name="watchlist"
            onChange={responseFormHandler}
            checked={series.watchlist}
          ></Checkbox>
        </div>

        <div>
          <label htmlFor="title">Archived</label>
          <Checkbox
            name="archived"
            onChange={responseFormHandler}
            checked={series.archived}
          ></Checkbox>
        </div>

        <div>
          <label htmlFor="title">Name</label>
          <InputText
            name="name"
            onChange={responseFormHandler}
            value={series.name}
          />
        </div>

        <div>
          <MultiSelect
            dataKey="name"
            filter={true}
            optionLabel="name"
            value={series.genres}
            options={allGenres}
            filterMatchMode="startsWith"
            scrollHeight="600px"
            onChange={(e: any) => setSelectedGenre(e.value)}
          />
        </div>

        <div>
          <label>Description</label>
          <InputTextarea
            value={series.description}
            onChange={responseFormHandler}
            name="description"
          />
        </div>
      </form>
    </div>
  )
}

export default EditSeries
