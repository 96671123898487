import { useEffect, useState } from 'react'

const useLocalStorage = (storageKey: string, fallbackState: any) => {
  const storageData = localStorage.getItem(storageKey)

  const [value, setValue] = useState(
    storageData ? JSON.parse(storageData) : fallbackState
  )

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return [value, setValue]
}

export default useLocalStorage
