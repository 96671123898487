import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { ReactComponent as IconUser } from '../../assets/icon-user.svg'
import { ReactComponent as Logo } from '../../assets/logo.svg'

const Mangaka = (props: any) => {
  const [mangakaNames, setMangakaNames] = useState<any>({
    amount: 0,
    data: [],
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const loadMangakaNames = async () => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/mangaka-names`)

    setMangakaNames(response.data)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [mangakaNames])

  useEffect(() => {
    loadMangakaNames()
  }, [])

  return (
    <main>
      <div className="container">
        {mangakaNames.amount > 0 && (
          <header className={`content-header ${isLoading ? ' hide-content' : ''}`}>
            <h2>
              <IconUser /> Mangaka ({mangakaNames.amount})
            </h2>
          </header>
        )}

        <div className={`index ${isLoading ? ' hide-content' : ''}`}>
          {Object.keys(mangakaNames.data).map((key: any) => (
            <div key={key} className="index-group">
              <h2>{key}</h2>
              {mangakaNames.data[key].map((mangaka: any) => (
                <div key={mangaka.id} className="index-item">
                  <Link to={`/search?q=${mangaka.name}`}>{mangaka.name}</Link>
                </div>
              ))}
              {!mangakaNames.data[key].length && (
                <div className="index-item">
                  <span>-</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </main>
  )
}

export default Mangaka
