import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { Checkbox } from 'primereact/checkbox'

const Add = () => {
  const [url, setUrl]: any = useState('')
  const [response, setResponse]: any = useState({
    title: '',
    series_list: [],
    volume_number: null,
    single: false,
    image_urls: [],
    selected_image_url: '',
    release_date_formatted: null,
    release_date_string: null,
    release_date_parsed: null,
    language: '',
    description: '',
    publisher_name: '',
    publisher_list: [],
    language_list: [],
    mangaka: [],
    mangaka_list: [],
    price: '',
    currency_value: '',
    currency_name: '',
    currency_symbol: '',
    page_number: null,
    watchlist: false,
    archived: false,
  })

  const parse = async (event: any) => {
    event.preventDefault()

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/parse`, {
      url: url,
    })

    const data = {
      ...res.data,
      release_date_parsed: new Date(res.data.release_date_parsed),
    }

    setResponse(data)
  }

  const save = async (event: any) => {
    event.preventDefault()

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/save`, {
      ...response,
    })

    window.location.reload()
  }

  const responseFormHandler = (event: any) => {
    let targetName = ''
    let value = ''

    if (event.checked) {
      targetName = event.target.name
      value = event.checked
    } else if (event.target) {
      targetName = event.target.name
      value = event.target.value
    } else {
      // InputNumber returns an originalEvent property.
      targetName = event.originalEvent.target.name
      value = event.value
    }

    setResponse((prev: any) => {
      return {
        ...prev,
        [targetName]: value,
      }
    })
  }

  const releaseDateHandler = (event: any) => {
    setResponse((prev: any) => {
      const date = new Date(event.target.value)

      if (date) {
        const formatted =
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()

        return {
          ...prev,
          release_date_formatted: formatted,
          release_date_parsed: event.target.value,
        }
      }
    })
  }

  const responseFormMangakaHandler = (index: number) => (event: any) => {
    setResponse((prev: any) => {
      const mangaka = prev.mangaka
      mangaka[index] = event.target.value

      return {
        ...prev,
        mangaka,
      }
    })
  }

  const selectImageUrl = (url: string) => (event: any) => {
    setResponse((prev: any) => {
      return {
        ...prev,
        selected_image_url: url,
      }
    })
  }

  const addMangaka = (event: any) => {
    event.preventDefault()

    setResponse((prev: any) => {
      const mangaka = prev.mangaka
      mangaka.push('')

      return {
        ...prev,
        mangaka,
      }
    })
  }

  const removeMangaka = (index: number) => (event: any) => {
    event.preventDefault()

    setResponse((prev: any) => {
      const mangaka = [...prev.mangaka]
      mangaka.splice(index, 1)

      return {
        ...prev,
        mangaka,
      }
    })
  }

  const onlyOneMangaka = response.mangaka.length === 1

  const mangakaDropdowns = response.mangaka.map((name: string, index: number) => {
    return (
      <div key={index}>
        <Dropdown
          optionLabel="name"
          editable
          optionValue="name"
          value={name}
          options={response.mangaka_list}
          onChange={responseFormMangakaHandler(index)}
          name="mangaka"
        />
        {!onlyOneMangaka && <button onClick={removeMangaka(index)}>-</button>}
      </div>
    )
  })

  return (
    <div className="sidebar-content">
      <div className="parse-container">
        <form onSubmit={parse}>
          <label>Amazon URL</label>
          <InputText
            autoFocus
            value={url}
            onChange={(e: any) => setUrl(e.target.value)}
          />
          <Button label="Parse" />
        </form>
      </div>

      <div className="selectable-images">
        {response.image_urls.map((url: string) => (
          <img
            onClick={selectImageUrl(url)}
            className={response.selected_image_url === url ? 'selected-image' : ''}
            key={url}
            width="250"
            src={url}
          />
        ))}
      </div>
      <form onSubmit={save}>
        <Button type="submit">CREATE</Button>

        <div>
          <label htmlFor="watchlist">Watchlist</label>
          <Checkbox
            name="watchlist"
            onChange={responseFormHandler}
            checked={response.watchlist}
          ></Checkbox>
        </div>

        <div>
          <label htmlFor="archived">Archived</label>
          <Checkbox
            name="archived"
            onChange={responseFormHandler}
            checked={response.archived}
          ></Checkbox>
        </div>

        <div>
          <label htmlFor="title">Titel</label>
          <Dropdown
            optionLabel="name"
            editable
            optionValue="name"
            value={response.title}
            options={response.series_list}
            onChange={responseFormHandler}
            name="title"
          />
        </div>
        <div>
          <label htmlFor="title">Release</label>
          <Calendar
            name="release_date_parsed"
            value={response.release_date_parsed}
            onChange={releaseDateHandler}
          ></Calendar>
        </div>
        <div>
          <label htmlFor="language">Sprache</label>
          <Dropdown
            optionLabel="name"
            editable
            optionValue="name"
            value={response.language}
            options={response.language_list}
            onChange={responseFormHandler}
            name="language"
          />
        </div>
        <div>
          <label htmlFor="title">Volume</label>
          <InputNumber
            name="volume_number"
            value={response.volume_number}
            maxFractionDigits={2}
            onChange={responseFormHandler}
            mode="decimal"
            disabled={response.single}
          />

          <Checkbox
            name="single"
            id="single"
            onChange={responseFormHandler}
            checked={response.single}
          ></Checkbox>
          <label htmlFor="single" className="p-checkbox-label">
            Single
          </label>
        </div>
        <div>
          <label htmlFor="title">Pages</label>
          <InputNumber
            name="page_number"
            value={response.page_number}
            maxFractionDigits={2}
            onChange={responseFormHandler}
            mode="decimal"
          />
        </div>
        <div>
          <label htmlFor="title">Preis</label>
          {response.currency_value && response.currency_name && (
            <InputNumber
              name="currency_value"
              value={response.currency_value}
              mode="currency"
              currency={response.currency_name}
              onChange={responseFormHandler}
            />
          )}
        </div>
        <div>
          <label htmlFor="title">Mangaka</label>
          <button onClick={addMangaka}>+</button>
          {mangakaDropdowns}
        </div>
        <div>
          <label htmlFor="title">Publisher</label>
          <Dropdown
            optionLabel="name"
            editable
            optionValue="name"
            value={response.publisher_name}
            options={response.publisher_list}
            onChange={responseFormHandler}
            name="publisher_name"
          />
        </div>
        <div>
          <label>Description</label>
          <InputTextarea
            value={response.description}
            onChange={responseFormHandler}
            name="description"
          />
        </div>
      </form>
    </div>
  )
}

export default Add
