import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { ReactComponent as IconUser } from '../../assets/icon-user.svg'
import { ReactComponent as IconHome } from '../../assets/icon-home.svg'
import { ReactComponent as IconCheck } from '../../assets/icon-check.svg'
import { ReactComponent as IconPen } from '../../assets/icon-pen.svg'
import { ReactComponent as IconEye } from '../../assets/icon-eye.svg'
import { ReactComponent as IconTrash } from '../../assets/icon-trash.svg'

import { debounce } from 'lodash'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import Add from '../../components/Sidebar/Add'
import { Sidebar } from 'primereact/sidebar'
import EditSeries from '../../components/Sidebar/EditSeries'

const SeriesDetail = (props: any) => {
  const [seriesList, setSeriesList] = useState<any>({
    name: '',
    volumes: [],
    last_volume: {},
    completed: false,
    watchlist: false,
    genres: [],
    first_volume: {
      title: '',
      publisher: {},
      mangaka: [],
    },
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [visibleSidebar, setVisibleSidebar] = useState(false)

  const params = useParams()

  const loadSeries = async () => {
    console.log('LOAD')
    setVisibleSidebar(false)

    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/series/${params.id}`
    )

    setSeriesList(response.data)
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }, [seriesList])

  const buildMangakaLinks = (mangakaList: any) => {
    if (mangakaList.length === 0) {
      return ''
    }

    return mangakaList
      .map((mangaka: any) => (
        <Link to="/" key={mangaka.id}>
          {mangaka.name}
        </Link>
      ))
      .reduce((prev: any, curr: any) => {
        return [prev, ', ', curr]
      })
  }

  useEffect(() => {
    loadSeries()
  }, [])

  return (
    <main>
      <Sidebar
        className="p-sidebar-md"
        visible={visibleSidebar}
        position="left"
        onHide={() => setVisibleSidebar(false)}
      >
        <EditSeries onSave={loadSeries} series={seriesList} />
      </Sidebar>

      {isLoading && (
        <div className="loading-container">
          <div>
            <Logo className="loading-logo" />
          </div>
        </div>
      )}

      <div className={`details-header ${isLoading ? ' hide-content' : ''}`}>
        <i
          className="details-backdrop"
          style={{ backgroundImage: `url(${seriesList.last_volume.cover_url})` }}
        ></i>

        <div className="container">
          <h2>
            {seriesList.name}

            {process.env.NODE_ENV === 'development' && (
              <button className="edit-btn" onClick={() => setVisibleSidebar(true)}>
                <IconPen />
              </button>
            )}
          </h2>
          <p>{seriesList.first_volume.description}</p>
          {seriesList.genres.length > 0 && (
            <div className="details-info-container">
              <div className="details-info">
                {seriesList.genres.map((genre: any) => (
                  <span
                    key={genre.id}
                    className="details-info-value highlight-simple"
                  >
                    {genre.name}
                  </span>
                ))}
              </div>
            </div>
          )}
          <div className="details-info-container">
            <div className="details-info">
              {seriesList.archived == true && (
                <span className="details-info-value highlight-alert">
                  <IconTrash />
                  Archived
                </span>
              )}
              {seriesList.watchlist == true && (
                <span className="details-info-value highlight-main2">
                  <IconEye />
                  Watchlist
                </span>
              )}
              {seriesList.first_volume.mangaka.map((mangaka: any) => (
                <Link
                  to={`/search?q=${mangaka.name}`}
                  key={mangaka.id}
                  className="details-info-value"
                >
                  <IconUser />
                  {mangaka.name}
                </Link>
              ))}
              <Link
                to={`/search?q=${seriesList.first_volume.publisher.name}`}
                className="details-info-value"
              >
                <IconHome />
                {seriesList.first_volume.publisher.name}
              </Link>
              {seriesList.completed == true && (
                <div className="details-info-value">
                  <IconCheck />
                  Completed
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="content-header"></div>

        <div className={`covers ${isLoading ? ' hide-content' : ''}`}>
          {seriesList.volumes.map((volume: any) => (
            <div className="series-container" key={volume.id}>
              <div className="cover-container">
                <img width="240" height="360" src={volume.cover_url} />
              </div>
              <div className="series-info-container">
                {volume.single == false && (
                  <span className="series-info">Volume {volume.number_parsed}</span>
                )}
                {volume.single == true && (
                  <span className="series-info">Single Volume</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  )
}

export default SeriesDetail
