import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { ReactComponent as IconBook } from '../../assets/icon-book.svg'
import { ReactComponent as Logo } from '../../assets/logo.svg'

const Series = (props: any) => {
  const [seriesNames, setSeriesNames] = useState<any>({
    amount: 0,
    data: [],
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const loadSeriesNames = async () => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/series-names`)

    setSeriesNames(response.data)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [seriesNames])

  useEffect(() => {
    loadSeriesNames()
  }, [])

  return (
    <main>
      <div className="container">
        {seriesNames.amount > 0 && (
          <header className={`content-header ${isLoading ? ' hide-content' : ''}`}>
            <h2>
              <IconBook /> Series ({seriesNames.amount})
            </h2>
          </header>
        )}

        <div className={`index ${isLoading ? ' hide-content' : ''}`}>
          {Object.keys(seriesNames.data).map((key: any) => (
            <div key={key} className="index-group">
              <h2>{key}</h2>
              {seriesNames.data[key].map((series: any) => (
                <div key={series.id} className="index-item">
                  <Link to={`/series/${series.id}/${series.slug}`}>
                    {series.name}
                  </Link>
                </div>
              ))}
              {!seriesNames.data[key].length && (
                <div className="index-item">
                  <span>-</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </main>
  )
}

export default Series
