import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

import { debounce } from 'lodash'
import { Link } from 'react-router-dom'
import series from '../Series/Series'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as IconBook } from '../../assets/icon-book.svg'
import { ReactComponent as IconCheck } from '../../assets/icon-check.svg'
import { Checkbox } from 'primereact/checkbox'
import useLocalStorage from '../../hooks/useLocalStorage'

const Home = (props: any) => {
  const [seriesList, setSeriesList] = useState<any>({})
  const [filters, setFilters] = useLocalStorage('global-filters', {
    completed: true,
    notCompleted: true,
    lastCover: true,
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const loadSeries = async () => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/series`, {
      params: {
        ...filters,
      },
    })

    setSeriesList(response.data)
  }

  useEffect(() => {
    loadSeries()
  }, [filters])

  useEffect(() => {
    if (seriesList.data?.length) {
      window.scrollTo(0, props.scrollPosition)
    }

    setTimeout(() => {
      setIsLoading(false)
    }, 400)
  }, [seriesList])

  const setScrollPosition = () => {
    props.setScrollPosition((prev: any) => {
      return {
        ...prev,
        home: window.scrollY,
      }
    })
  }

  const filterHandler = (event: any) => {
    let targetName = ''
    let value = ''

    if (event.checked) {
      targetName = event.target.name
      value = event.checked
    } else {
      targetName = event.target.name
      value = event.target.value
    }

    props.setScrollPosition((prev: any) => {
      return {
        ...prev,
        home: 0,
      }
    })

    setFilters((prev: any) => {
      return {
        ...prev,
        [targetName]: value,
      }
    })
  }

  const buildMangakaLinks = (mangakaList: any) => {
    if (mangakaList.length === 0) {
      return ''
    }

    return mangakaList
      .map((mangaka: any) => (
        <Link
          onClick={setScrollPosition}
          to={`/search?q=${mangaka.name}`}
          key={mangaka.id}
        >
          {mangaka.name}
        </Link>
      ))
      .reduce((prev: any, curr: any) => {
        return [prev, ', ', curr]
      })
  }

  return (
    <main>
      {isLoading && (
        <div className="loading-container">
          <div>
            <Logo className="loading-logo" />
          </div>
        </div>
      )}
      <div className="container">
        {seriesList.amount === 0 && !isLoading && (
          <div className="no-content">
            <span>Nothing Found</span>
          </div>
        )}

        {seriesList.amount > 0 && (
          <header className={`content-header ${isLoading ? ' hide-content' : ''}`}>
            <h2>
              <IconBook /> All ({seriesList.amount})
            </h2>

            <div className="content-header-actions">
              <div className="content-header-action">
                <Checkbox
                  checked={filters.completed}
                  onChange={filterHandler}
                  name="completed"
                  inputId="completed"
                />
                <label htmlFor="completed">Completed</label>
              </div>
              <div className="content-header-action">
                <Checkbox
                  checked={filters.notCompleted}
                  onChange={filterHandler}
                  name="notCompleted"
                  inputId="notCompleted"
                />
                <label htmlFor="notCompleted">Not completed</label>
              </div>
              <div className="content-header-action">
                <Checkbox
                  checked={filters.lastCover}
                  onChange={filterHandler}
                  name="lastCover"
                  inputId="lastCover"
                />
                <label htmlFor="lastCover">Last cover</label>
              </div>
            </div>
          </header>
        )}

        <div className={`covers ${isLoading ? ' hide-content' : ''}`}>
          {seriesList.data?.map((series: any) => (
            <div className="series-container" key={series.id}>
              <Link
                onClick={setScrollPosition}
                to={`/series/${series.id}/${series.slug}`}
                className="cover-container"
              >
                <span className="cover-info">
                  <span title="Volumes">
                    <IconBook />
                    {series.volumes_count}
                  </span>
                  {series.completed == true && (
                    <span title="Completed">
                      <IconCheck />
                    </span>
                  )}
                </span>
                <img
                  width="240"
                  height="360"
                  src={
                    series[filters.lastCover ? 'last_volume' : 'first_volume']
                      .cover_url
                  }
                />
              </Link>
              <div className="series-info-container">
                <div className="series-name">
                  <Link
                    onClick={setScrollPosition}
                    to={`/series/${series.id}/${series.slug}`}
                  >
                    {series.name}
                  </Link>
                </div>
                <span className="series-info">
                  {buildMangakaLinks(
                    series[filters.lastCover ? 'last_volume' : 'first_volume']
                      .mangaka
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  )
}

export default Home
