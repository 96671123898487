import './Header.scss'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as IconBook } from '../../assets/icon-book.svg'
import { ReactComponent as IconUser } from '../../assets/icon-user.svg'
import { ReactComponent as IconTrash } from '../../assets/icon-trash.svg'
import { ReactComponent as IconEye } from '../../assets/icon-eye.svg'
import { ReactComponent as IconSearch } from '../../assets/icon-search.svg'
import { ReactComponent as IconPlus } from '../../assets/icon-plus.svg'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { Sidebar } from 'primereact/sidebar'
import Add from '../Sidebar/Add'
import axios from 'axios'

const Header = (props: any) => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchRef = useRef<any>()

  const [visibleSidebar, setVisibleSidebar] = useState(false)

  const currentType = location.pathname === '/' ? 'home' : 'series'

  const search = (event: any) => {
    event.preventDefault()

    const value = searchRef.current.value

    if (!value) {
      return
    }

    setScrollPosition()

    navigate(`/search?q=${value}`)
  }

  const setScrollPosition = () => {
    if (currentType === 'home') {
      props.setScrollPosition((prev: any) => {
        return {
          ...prev,
          home: window.scrollY,
        }
      })
    }
  }

  const resetScrollPositions = (type: any) => (event: any) => {
    if (currentType === 'home') {
      window.scrollTo(0, 0)
    }

    props.setScrollPosition((prev: any) => {
      return {
        ...prev,
        [type]: 0,
      }
    })
  }

  return (
    <>
      <Sidebar
        className="p-sidebar-md"
        visible={visibleSidebar}
        position="left"
        dismissable={false}
        onHide={() => setVisibleSidebar(false)}
      >
        <Add />
      </Sidebar>
      <header className="header">
        <div className="container">
          <div className="header-items">
            <Link
              className="hover-able-logo"
              to="/"
              onClick={resetScrollPositions('home')}
            >
              <Logo />
            </Link>

            <div className="header-actions">
              <form onSubmit={search} className="search-container">
                <IconSearch />
                <input ref={searchRef} placeholder="Search series or mangaka..." />
              </form>

              {process.env.NODE_ENV === 'development' && (
                <button className="add-btn" onClick={() => setVisibleSidebar(true)}>
                  <IconPlus />
                </button>
              )}
            </div>

            <nav>
              <NavLink to="series">
                <IconBook />
                Series
              </NavLink>
              <NavLink to="mangaka">
                <IconUser />
                Mangaka
              </NavLink>
              <NavLink onClick={resetScrollPositions('watchlist')} to="watchlist">
                <IconEye />
                Watchlist
              </NavLink>
              <NavLink onClick={resetScrollPositions('archived')} to="archived">
                <IconTrash />
                Archived
              </NavLink>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
