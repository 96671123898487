import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

import { debounce } from 'lodash'
import { Link, useLocation, useRoutes, useSearchParams } from 'react-router-dom'
import series from '../Series/Series'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as IconBook } from '../../assets/icon-book.svg'
import { ReactComponent as IconCheck } from '../../assets/icon-check.svg'
import { ReactComponent as IconEye } from '../../assets/icon-eye.svg'
import { ReactComponent as IconTrash } from '../../assets/icon-trash.svg'
import useLocalStorage from '../../hooks/useLocalStorage'

const Search = (props: any) => {
  const [seriesList, setSeriesList] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [search, setSearch] = useSearchParams()
  const [filters, setFilters] = useLocalStorage('global-filters', {
    completed: true,
    notCompleted: true,
    lastCover: true,
  })

  const loadSearch = async () => {
    // setIsLoading(true)

    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/search?q=${search.get('q')}`
    )

    setSeriesList(response.data)
  }

  useEffect(() => {
    loadSearch()
  }, [search])

  useEffect(() => {
    if (seriesList.length) {
      window.scrollTo(0, props.scrollPosition)
    }

    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }, [seriesList])

  const buildMangakaLinks = (mangakaList: any) => {
    if (mangakaList.length === 0) {
      return ''
    }

    return mangakaList
      .map((mangaka: any) => (
        <Link to={`/search?q=${mangaka.name}`} key={mangaka.id}>
          {mangaka.name}
        </Link>
      ))
      .reduce((prev: any, curr: any) => {
        return [prev, ', ', curr]
      })
  }

  return (
    <main>
      {isLoading && (
        <div className="loading-container">
          <div>
            <Logo className="loading-logo" />
          </div>
        </div>
      )}
      <div className="container">
        <div className="content-header"></div>

        {!seriesList.length && !isLoading && (
          <div className="no-content">
            <span>Nothing Found</span>
          </div>
        )}

        {seriesList.length > 0 && (
          <div className={`covers ${isLoading ? ' hide-content' : ''}`}>
            {seriesList.map((series: any) => (
              <div className="series-container" key={series.id}>
                <Link
                  to={`/series/${series.id}/${series.slug}`}
                  className="cover-container"
                >
                  <span className="cover-info on-top">
                    {series.watchlist == true && (
                      <span title="Watchlist">
                        <IconEye />
                      </span>
                    )}

                    {series.archived == true && (
                      <span title="Archived" className="background-alert">
                        <IconTrash />
                      </span>
                    )}
                  </span>

                  <span className="cover-info">
                    <span title="Volumes">
                      <IconBook />
                      {series.volumes_count}
                    </span>
                    {series.completed == true && (
                      <span title="Completed">
                        <IconCheck />
                      </span>
                    )}
                  </span>
                  <img
                    width="240"
                    height="360"
                    src={
                      series[filters.lastCover ? 'last_volume' : 'first_volume']
                        .cover_url
                    }
                  />
                </Link>
                <div className="series-info-container">
                  <div className="series-name">
                    <Link to={`/series/${series.id}/${series.slug}`}>
                      {series.name}
                    </Link>
                  </div>
                  <span className="series-info">
                    {buildMangakaLinks(
                      series[filters.lastCover ? 'last_volume' : 'first_volume']
                        .mangaka
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </main>
  )
}

export default Search
